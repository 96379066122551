<template>
  <div>
    <v-container fluid class="py-6 profile">
      <Loading v-if="loading"></Loading>
      <Notify></Notify>

      <v-row>
        <v-col cols="1">
          <!-- :class="classLangPrint" -->
          <v-btn
            :ripple="false"
            class="text-white mt-3 font-weight-bolder"
            small
            color="#c7373a"
            @click="print()"
            >{{ $t("print") }}</v-btn
          >
        </v-col>
        <v-col cols="1">
          <v-btn
            :ripple="false"
            class="text-white mt-3 font-weight-bolder"
            small
            color="#047a6f"
            @click="show()"
            >{{ $t("show") }}</v-btn
          >
        </v-col>
        <v-col cols="1">
          <v-btn
            :ripple="false"
            class="text-white mt-3 font-weight-bolder"
            small
            color="#06ab95d4"
            @click="show_all()"
            >{{ $t("show all") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="light">
            <v-app-bar
              color="light"
              class="border-left-primary chart_head pt-3"
            >
              <!-- <v-toolbar-title> -->
              <v-select
                class="chart_select"
                :items="years"
                v-model="selected_year"
                label="Year"
                sm="2"
                @change="redraw()"
                :disabled="radioCheck == 1"
              ></v-select>
              <v-radio-group v-model="radioCheck" row v-on:change="redraw()">
                <v-radio label="Monthly" value="0"></v-radio>
                <v-radio label="Yearly" value="1"></v-radio>
                <v-radio label="Quarterly" value="2"></v-radio>
              </v-radio-group>
              <!-- </v-toolbar-title> -->

              <v-select
                class="chart_select"
                :items="total_inquiry"
                v-model="selected_inquiry"
                item-value="id"
                item-text="name"
                label
                return-object
                multiple
                sm="2"
                @change="redraw()"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption"
                    >(+{{ selected_inquiry.length - 1 }} others)</span
                  >
                </template>
              </v-select>
            </v-app-bar>
            <div id="chartBar">
              <canvas id="myChart" height="100"></canvas>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col cols="12" sm="6">
          <v-row class="flex-column">
            <!-- <v-col cols="12">
            <v-card class="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>{{ $t("Info contact") }}</v-toolbar-title>
              </v-app-bar>
              <v-card class="mx-auto border border-top-none" title>
                <v-list class="text-center">
                  <v-list-item>
                    <v-list-item-avatar
                      width="60px"
                      height="60px"
                      class="mt-7 mx-auto"
                      v-if="customer.users"
                    >
                      <v-img
                        v-if="customer.users.avatar"
                        :src="
                          $baseURL + 'storage/users/' + customer.users.avatar
                        "
                      ></v-img>
                      <v-img
                        v-else
                        class="border-radius-lg shadow-lg"
                        src="@/assets/img/alborj/no-avatar.jpg"
                      ></v-img>
                    </v-list-item-avatar>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title v-if="customer.users" class="text-h6"
                        >{{ customer.users.user }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <hr class="horizontal dark my-4" />
                <v-list nav dense>
                  <v-list-item v-for="(item, i) in info" :key="i">
                    <v-list-item-icon class="me-3">
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-card>
          </v-col> -->
            <v-col cols="12">
              <v-card class="light">
                <v-app-bar color="light" class="border-left-primary">
                  <v-toolbar-title>{{ $t("Edit Info") }}</v-toolbar-title>
                </v-app-bar>
                <v-card class="mx-auto border border-top-none px-5 py-7" title>
                  <!-- <div class="px-6 pb-6 pt-0"> -->
                  <v-row>
                    <v-col sm="12" cols="12" class="text-center">
                      <v-badge
                        bottom
                        color="bg-gradient-light accent-4 text-dark text-xxs border-radius-md shadow"
                        offset-x="10"
                        offset-y="10"
                        :icon="avatar_src ? '' : 'fa-plus text-xxs'"
                      >
                        <v-avatar rounded width="110" height="110">
                          <img
                            v-if="saved_avatar_src && avatar_src == ''"
                            :src="
                              $baseURL + 'storage/users/' + saved_avatar_src
                            "
                            :lazy-src="
                              $baseURL + 'storage/users/' + saved_avatar_src
                            "
                            alt="Avatar"
                            class="border-radius-lg"
                          />
                          <img
                            v-if="avatar_src"
                            :src="avatar_src"
                            :lazy-src="avatar_src"
                            alt="Avatar"
                            class="border-radius-lg"
                          />
                          <img
                            v-if="
                              (avatar_src == '' || avatar_src == null) &&
                              (saved_avatar_src == '' ||
                                saved_avatar_src == null)
                            "
                            src="@/assets/img/no-avatar.jpg"
                            alt="Avatar"
                            class="border-radius-lg"
                          />
                          <span
                            @click="removeImage()"
                            v-if="avatar_src"
                            class="
                              v-badge__badge
                              bg-gradient-light
                              accent-4
                              text-dark text-xxs
                              border-radius-md
                              shadow
                            "
                            style="
                              inset: calc(100% - 10px) auto auto
                                calc(100% - 10px);
                              z-index: 9;
                              cursor: pointer;
                            "
                          >
                            <i
                              aria-hidden="true"
                              class="
                                v-icon
                                notranslate
                                fa fa-times
                                text-xxs
                                theme--light
                              "
                            ></i>
                          </span>

                          <v-file-input
                            class="custom-avatar"
                            id="files"
                            ref="files"
                            accept="image/png, image/jpeg, image/bmp"
                            @change="handleFilesUpload"
                            v-model="files"
                          />
                        </v-avatar>
                      </v-badge>
                      <div class="text-center mt-6">
                        <v-btn
                          color="#cb0c9f"
                          class="
                            font-weight-bolder
                            btn-default
                            bg-gradient-default
                            py-4
                            px-3
                            ms-auto
                            mt-sm-auto mt-4
                          "
                          small
                          @click="DeleteImage()"
                          :loading="loadingImage"
                        >
                          <span slot="loader">
                            <v-progress-circular
                              style="width: 20px; height: 20px"
                              indeterminate
                              color="white"
                            >
                            </v-progress-circular>
                          </span>
                          {{ $t("Delete Image") }}
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col sm="12" cols="12">
                      <Form
                        @save="handleUpdate"
                        :loader="loadingInfo"
                        :data="form_info_basic"
                      ></Form>
                    </v-col>
                    <!-- reset password -->
                    <v-col sm="12" cols="12">
                      <h4 class="mb-8">{{ $t("auth.Reset Password") }}</h4>
                      <v-form ref="resetPasswordForm">
                        <v-row>
                          <v-col cols="12" class="pt-0">
                            <label
                              class="text-xs text-typo font-weight-bolder ms-1"
                              >{{ $t("user.New password") }}</label
                            >
                            <v-text-field
                              outlined
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show1 ? 'text' : 'password'"
                              @click:append="show1 = !show1"
                              v-model="passwords.password"
                              :rules="rules.password"
                              lazy-validation
                              color="rgba(0,0,0,.6)"
                              light
                              class="
                                font-size-input
                                placeholder-lighter
                                mt-2
                                mb-4
                              "
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="pt-0">
                            <label
                              class="text-xs text-typo font-weight-bolder ms-1"
                              >{{ $t("user.Confirm new password") }}</label
                            >
                            <v-text-field
                              outlined
                              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show2 ? 'text' : 'password'"
                              @click:append="show2 = !show2"
                              v-model="passwords.password_confirmation"
                              :rules="rules.password_confirmation"
                              lazy-validation
                              color="rgba(0,0,0,.6)"
                              light
                              class="
                                font-size-input
                                placeholder-lighter
                                mt-2
                                mb-4
                              "
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <div class="text-right">
                          <v-btn
                            @click="ResetPassword()"
                            class="btn-primary py-5 px-6 mb-"
                            :loading="loadingPassword"
                            small
                          >
                            <span slot="loader">
                              <v-progress-circular
                                style="width: 20px; height: 20px"
                                indeterminate
                                color="white"
                              >
                              </v-progress-circular>
                            </span>
                            {{ $t("auth.Reset Password") }}
                          </v-btn>
                        </div>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-card>
              </v-card>
            </v-col>

            <Tab
              :tabs="tab_form_col_1"
              @save="save_form"
              :inquiries_data="inquiries_data_col1"
              :type="'1'"
            ></Tab>
            <!-- :loader="loader_form" -->
          </v-row>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row class="flex-column">
            <v-col
              cols="12"
              v-if="customer.inquiries && customer.inquiries.length > 0"
            >
            <div class="print_section">
              <v-card class="light outlined">
                <v-app-bar color="light" class="border-left-primary">
                  <v-toolbar-title>{{ $t("Returns") }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="add_inquiry()">
                    <v-icon>mdi-cog-outline</v-icon>
                  </v-btn>
                </v-app-bar>
                <v-card class="mx-auto border border-top-none" title>
                  <!-- <v-list-item>
                  <v-list-item-content class="py-0 pt-3">
                    <input
                      type="text"
                      v-model="test"
                      class="border"
                    />
                  </v-list-item-content>
                </v-list-item> -->
                  <v-list-item
                    class="py-3"
                    v-for="(item, i) in customer.inquiries"
                    :key="i"
                  >
                    <!-- {{ item }} -->
                    <v-list-item-content class="py-0 pt-3">
                      <v-row>
                        <v-col cols="3" class="pt-0">
                          <div class="d-flex flex-column">
                            <div class="d-flex">
                              <v-btn
                                icon
                                @click="edit_inquery(item)"
                                class="text-typo"
                                style="width: 30px"
                              >
                                <v-icon size="15">mdi-pencil</v-icon>
                              </v-btn>
                              <v-btn
                                style="width: 30px"
                                icon
                                @click="delete_inquery(item)"
                                class="text-typo"
                              >
                                <v-icon size="15">mdi-delete</v-icon>
                              </v-btn>
                            </div>
                            <div>
                              <h6
                                v-if="item.name == 'currency'"
                                class="
                                  mb-1
                                  text-body text-lg
                                  font-weight-bold
                                  text-h5
                                  mx-3
                                "
                              >
                                SAR
                              </h6>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="9">
                          <div class="d-flex flex-column text-end">
                            <span
                              class="text-xs text-typo mb-3 font-weight-bold"
                              >{{ item.inquery_lang }}</span
                            >
                            <div v-if="item.answer != null">
                              <a
                                v-if="item.type == 'file'"
                                target="_blank"
                                class="text-decoration-none"
                                :href="$baseURL + item.answer"
                              >
                                <v-btn
                                  color="#fff"
                                  class="
                                    text-dark
                                    font-weight-bolder
                                    py-6
                                    px-1
                                    shadow-0
                                  "
                                  small
                                  simple
                                >
                                  <v-icon size="18" class="rotate-180"
                                    >fas fa-file-pdf text-lg me-1</v-icon
                                  >
                                  {{ $t("ticket.file") }}
                                </v-btn>
                              </a>
                              <h6
                                v-else
                                class="
                                  mb-1
                                  text-body text-lg
                                  font-weight-bold
                                  text-h4
                                "
                              >
                                <span>{{ item.answer }}</span>
                              </h6>
                            </div>
                            <h6
                              class="
                                mb-1
                                text-body text-lg
                                font-weight-bold
                                text-h4
                              "
                              v-else
                            >
                              -
                            </h6>
                          </div>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-card>
            </div>
            </v-col>

            <v-col cols="12" v-if="repeatable_inquiries">
              <v-card class="light">
                <v-app-bar color="light" class="border-left-primary">
                  <v-toolbar-title>{{
                    $t("Monthly Inquiries")
                  }}</v-toolbar-title>
                </v-app-bar>
                <v-card class="mx-auto border border-top-none" title>
                  <v-list-item
                    class="py-3"
                    v-for="(item, year) in repeatable_inquiries"
                    :key="year"
                  >
                    <v-list-group
                      prepend-icon="mdi-clipboard-text"
                      no-action
                      class="w-100 px-0 py-3 stages"
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>{{ year }}</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                        class="py-3"
                        v-for="(itemMonth, month) in item"
                        :key="month"
                      >
                        <v-list-group
                          prepend-icon="mdi-clipboard-text"
                          no-action
                          class="w-100 px-0 py-3 stages"
                        >
                          <template v-slot:activator>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                month | getMonth
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </template>

                          <div class="mt-6">
                            <v-row>
                              <v-col
                                class="pt-0"
                                v-for="(inquery, index) in itemMonth"
                                :key="index"
                                cols="12"
                              >
                                <v-row>
                                  <v-col cols="12" sm="12">
                                    <div
                                      class="
                                        position-relative
                                        d-flex
                                        justify-space-between
                                      "
                                    >
                                      <label
                                        class="
                                          text-xs text-typo
                                          font-weight-bolder
                                          ms-1
                                          mt-2
                                        "
                                        >{{ inquery.inquery_lang }}</label
                                      >
                                      <v-btn
                                        style="width: 30px"
                                        icon
                                        @click="
                                          delete_inquery_repeatable(inquery)
                                        "
                                        class="text-typo"
                                      >
                                        <v-icon size="15">mdi-delete</v-icon>
                                      </v-btn>
                                    </div>
                                  </v-col>
                                </v-row>
                                <div class="position-relative">
                                  <v-text-field
                                    :type="inquery.type"
                                    outlined
                                    color="rgba(0,0,0,.6)"
                                    class="
                                      font-size-input
                                      placeholder-lighter
                                      text-light-input
                                    "
                                    v-model="
                                      inquiries_data_monthly[year][month][index]
                                        .answer
                                    "
                                  ></v-text-field>
                                </div>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col :class="classLangSave" cols="12">
                                <v-btn
                                  class="btn-primary mw-80"
                                  color="white"
                                  text
                                  @click="save_inquery_repeatble(year, month)"
                                  >{{ $t("form.save") }}</v-btn
                                >
                              </v-col>
                            </v-row>
                          </div>
                        </v-list-group>
                      </v-list-item>
                    </v-list-group>
                  </v-list-item>
                </v-card>
              </v-card>
            </v-col>
            <Tab
              :tabs="tab_form_col_2"
              :inquiries_data="inquiries_data_col2"
              @save="save_form"
              :type="'2'"
            ></Tab>
            <!-- :loader="loader_form" -->
          </v-row>
        </v-col>
      </v-row>
      <div class="print_container">
        <v-row>
          <v-col 
          cols="6"
            v-for="(item, i) in tabs_print"
            :key="i"
            :class="[
              $store.state.customer.print_selected.includes(item.id)
                ? 'print_section'
                : '',
            ]"
            v-if="$store.state.customer.print_selected.includes(item.id)"
          >
  
          <v-card
            class="light outlined"
          >
            <v-app-bar color="light" class="border-left-primary">
              <v-toolbar-title>{{ item.name }}</v-toolbar-title>
            </v-app-bar>
  
            <v-card class="mx-auto border border-top-none px-5 py-7" title>
              <v-row>
                <v-col
                  class="pt-0 mb-2"
                  v-for="(inquery, index) in item.get_inquiries"
                  :key="index"
                  cols="12"
                >
                  <v-row>
                    <v-col cols="12" sm="10">
                      <div class="position-relative">
                        <label
                          class="text-xs text-typo font-weight-bolder ms-1"
                          >{{ inquery.inquery_lang }}</label
                        >
                      </div>
                    </v-col>
                  </v-row>
                  <!-- <div
                    class="position-relative"
                    v-if="inquery.inputs.type == 'file'"
                  >
                    <v-btn
                      color="#fff"
                      class="text-dark font-weight-bolder py-6 px-2 shadow-0"
                      small
                      simple
                    >
                      <v-icon size="18" class="rotate-180"
                        >fas fa-file-pdf text-lg me-1</v-icon
                      >
                      {{ $t("ticket.file") }}
                    </v-btn>
                  </div> -->
                  <div class="position-relative" >
                    <div v-if="inquery.register_form_answers && inquery.inputs.type != 'file'" class="answer">
                      {{ inquery.register_form_answers.answer }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
          </v-col>
        </v-row>
      </div>
      <Modal :data="modal_data"></Modal>
    </v-container>
  </div>
</template>
<script>
import Chart from "chart.js/auto";
import Modal from "../../Components/New/Modal.vue";
import Loading from "../../Components/Loading.vue";
import Notify from "../../Components/New/Notify.vue";
import Tab from "../../Components/Tab.vue";
// import Tab from "./Tab.vue";
import CustomerService from "../../../services/customer.service";
import Form from "../../Components/Old/Form.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("customer");
export default {
  name: "Profile-Customer",
  components: {
    Modal,
    Loading,
    Form,
    Notify,
    Tab,
  },
  data() {
    return {
      test: 3,
      tab_form_col_1: [],
      tab_form_col_2: [],
      loading: true,
      info: [],
      modal_data: {
        size: "500px",
        title: "",
      },
      message: {
        msg: "",
        type: "",
      },
      tabs_print: [],
      form_data: {
        inquiry_id: "",
        answer: null,
        status: false,
        repeatable: false,
        admins: false,
        type: "",
      },
      form_style: [
        {
          col: "12",
          type: "autocomplete",
          type_select: "single",
          label: this.$i18n.t("inquiry.Inquery Text"),
          value_text: "inquiry_id",
          title_select: "inquery_lang",
          items: [],
          action: "selectInquery",
          error: null,
          disabled: false,
          visible: true,
        },
        {
          col: "12",
          type: "text",
          label: this.$i18n.t("inquiry.answer"),
          error: null,
          value_text: "answer",
          disabled: false,
          visible: true,
          rules: [],
        },
        {
          col: "12",
          type: "checkbox",
          label: "",
          value_text: "status",
          label_input: this.$i18n.t("Assign For Customer"),
          error: null,
          value: "",
          color: "success",
          visible: true,
          rules: [],
        },
        {
          col: "6",
          type: "checkbox",
          label: "",
          value_text: "repeatable",
          label_input: this.$i18n.t("repeatable"),
          error: null,
          value: "",
          color: "success",
          col_id: "repeatable",
          visible: true,
          rules: [],
          disabled: false,
        },
        {
          col: "6",
          type: "checkbox",
          label: "",
          value_text: "admins",
          label_input: this.$i18n.t("only admins"),
          error: null,
          col_id: "admins",
          value: "",
          color: "success",
          rules: [],
          visible: true,
          action: "show_hide_repeatble",
        },
      ],
      form_data_print: {
        print_array: [],
      },
      form_style_print: [
        {
          col: "12",
          type: "autocomplete",
          type_select: "multiple",
          label: this.$i18n.t("select sections for print"),
          value_text: "print_array",
          title_select: "name",
          items: [],
          error: null,
          disabled: false,
        },
      ],
      show_all_cards: [],
      tabs: [],
      total_inquiry: [],
      selected_inquiry: [],
      labels: [],
      datasets: [],
      chart_data: [],
      radioCheck: "0",
      years: [],
      selected_year: null,
      inquiries_data_monthly: [],
      inquiries_data_col1: [],
      inquiries_data_col2: [],
      form_info_basic: [
        {
          col: 12,
          type: "text",
          label: this.$i18n.t("First Name"),
          error: null,
          value: "",
          value_text: "first_name",
          rules: [
            (v) => !!v || this.$i18n.t("first name is required"),
            (v) =>
              v.length <= 20 ||
              this.$i18n.t("Name must be less than 20 characters"),
          ],
        },
        {
          col: 12,
          type: "text",
          label: this.$i18n.t("Last Name"),
          error: null,
          value: "",
          value_text: "last_name",
          rules: [
            (v) => !!v || this.$i18n.t("last name is required"),
            (v) =>
              v.length <= 20 ||
              this.$i18n.t("auth.Name must be less than 20 characters"),
          ],
        },
        {
          col: 12,
          type: "text",
          label: this.$i18n.t("auth.Email Address"),
          error: null,
          value: "",
          value_text: "email",
          rules: [
            // (v) => !!v || this.$i18n.t("auth.E-mail is required"),

            (v) =>
              !v ||
              (v &&
                /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
                  v
                )) ||
              this.$i18n.t("auth.E-mail must be valid"),
          ],
        },
        {
          col: 12,
          type: "text",
          label: this.$i18n.t("auth.Phone Number"),
          error: null,
          value: "",
          value_text: "phone",
          rules: [
            // (v) => !!v || this.$i18n.t("auth.Phone Number is required")
          ],
        },
        {
          col: 12,
          type: "text",
          label: this.$i18n.t("Company Name"),
          error: null,
          value: "",
          value_text: "company",
          rules: [
            // (v) => !!v || this.$i18n.t("auth.Phone Number is required")
          ],
        },
      ],
      rules: {
        password: [
          (v) => !!v || this.$i18n.t("auth.Password is required"),
          (v) =>
            (v && v.length >= 6) ||
            this.$i18n.t("auth.minimum 6 characters at least"),
        ],
        password_confirmation: [
          (v) => !!v || this.$i18n.t("auth.Password Confirmation is required"),
          (v) =>
            v == this.passwords.password ||
            this.$i18n.t(
              "auth.Password and password confirmation does not match"
            ),
        ],
      },
      loadingInfo: false,
      loadingImage: false,
      loadingPassword: false,
      errors: {},
      error_msg: "",
      files: null,
      active: false,
      avatar_src: "",
      saved_avatar_src: "",
      form: {
        first_name: "",
        last_name: "",
        phone: "",
        company: "",
        email: "",
      },
      passwords: {
        password: "",
        password_confirmation: "",
      },
      confirm: false,
      show1: false,
      show2: false,
    };
  },
  computed: {
    ...mapState([
      "customer",
      "inqueries",
      "repeatable_inquiries",
      "print_selected",
    ]),
    classLang() {
      return {
        "pl-0": !this.$vuetify.rtl,
        "pr-0": this.$vuetify.rtl,
      };
    },
    classLangPrint() {
      return {
        "mr-6": this.$vuetify.rtl,
        "ml-6": !this.$vuetify.rtl,
      };
    },
    classLangSave() {
      return {
        "text-left": this.$vuetify.rtl,
        "text-right": !this.$vuetify.rtl,
      };
    },
  },
  methods: {
    ...mapActions([
      "getCustomerDetails",
      "saveAnswerRepeatableInquiries",
      "deleteInqueryRepeatable",
    ]),
    inquiry_chart() {
      var self = this;
      var crt = new Chart(document.getElementById("myChart"), {
        type: "bar",
        data: {
          labels: self.labels,
          // datasets: [{
          //     label: '# of Votes',
          //     data: self.chart_data,
          //     backgroundColor:'#047a6f',
          //     borderWidth: 1
          // }]
          datasets: self.datasets,
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
    get_chart_inquiries() {
      var self = this;
      return CustomerService.get_chart_inquiries(this.$route.params.id).then(
        (response) => {
          response.data.map(function (item) {
            var tmp = {};
            tmp["name"] = item.inquery_lang;
            tmp["id"] = item.inquiry_id;
            self.total_inquiry.push(tmp);
          });
          // if(self.selected_inquiry.length == 0)
          // self.selected_inquiry[0] = self.total_inquiry[0];
          this.get_chart_data();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    get_chart_data() {
      var self = this;
      let inquiries_ids = [];
      this.selected_inquiry.map((a) => inquiries_ids.push(a.id));
      self.labels = [];
      self.datasets = [];

      let formData = new FormData();
      let type;
      if (self.radioCheck == 0) {
        type = "monthly";
      } else if (self.radioCheck == 1) {
        type = "yearly";
      } else {
        type = "quarterly";
      }
      formData.append("type", type);
      inquiries_ids.forEach((item) => formData.append("inquiries[]", item));
      formData.append("user_id", this.$route.params.id);
      formData.append("year", self.selected_year);

      return CustomerService.get_chart_data(formData).then(
        (response) => {
          self.labels = response.data.labels;
          self.datasets = response.data.datasets;
          this.inquiry_chart();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    redraw() {
      document.getElementById("myChart").remove();
      document
        .getElementById("chartBar")
        .insertAdjacentHTML(
          "beforeend",
          '<canvas id="myChart" height="100"></canvas>'
        );
      this.get_chart_inquiries();
    },
    getDetails() {
      this.loading = true;
      this.getCustomerDetails(this.$route.params.id).then(
        (response) => {
          this.loading = false;
          var res;
          var customer = this.customer;
          this.tabs_print = response.data.tabs;
          this.inquiries_data_monthly = this.repeatable_inquiries;
          res = response.data.tabs;
          var count = res.length;

          if (count % 2 == 0) {
            // if count double
            var length = count / 2;
            this.set_tabs_into_2_array(res, count, length);
            this.show_all_cards = this.print_selected;
          } else {
            var count1 = count - 1;
            var length = count1 / 2;
            this.set_tabs_into_2_array(res, count1, length);
            var last_element = res[count1];
            this.show_all_cards = this.print_selected;
            if (last_element.get_inquiries.length > 0) {
              this.tab_form_col_1.push(last_element);
              this.$store.state.customer.print_selected.push(last_element.id);
              this.show_all_cards.push(last_element.id);
              this.tabs.push({ id: last_element.id, name: last_element.name });
              var array = this.set_inquery_data(response.data.tabs[count1]);
              this.inquiries_data_col1.push(array);
            }
          }
          this.saved_avatar_src = customer.users.avatar;
          this.form_info_basic.map(function (item) {
            if (customer.users[item.value_text] != null)
              item.value = customer.users[item.value_text];
          });
          // if (this.customer.p_company_name != null) {
          //   this.info.push({
          //     text: this.customer.p_company_name,
          //     icon: "mdi-home-outline",
          //   });
          // } else {
          //   this.info.push({
          //     text: this.$i18n.t("Not Specified"),
          //     icon: "mdi-home-outline",
          //   });
          // }

          // info section
          // if (this.customer.users.phone != null) {
          //   this.info.push({
          //     text: this.customer.users.phone,
          //     icon: "mdi-phone-outline",
          //   });
          // } else {
          //   this.info.push({
          //     text: this.$i18n.t("Not Specified"),
          //     icon: "mdi-phone-outline",
          //   });
          // }
          // if (this.customer.users.company != null) {
          //   this.info.push({
          //     text: this.customer.users.company,
          //     icon: "mdi-domain",
          //   });
          // } else {
          //   this.info.push({
          //     text: this.$i18n.t("Not Specified"),
          //     icon: "mdi-domain",
          //   });
          // }
          // if (this.customer.users.email != null) {
          //   this.info.push({
          //     text: this.customer.users.email,
          //     icon: "mdi-email-outline",
          //   });
          // } else {
          //   this.info.push({
          //     text: this.$i18n.t("Not Specified"),
          //     icon: "mdi-email-outline",
          //   });
          // }
        },
        (error) => {
          this.loading = false;
          // console.log(error);
        }
      );
    },
    set_tabs_into_2_array(data, count, length) {
      var y = 0;
      var section = 2;
      var i;
      for (i = 0; i < length; i++) {
        const element = data[i];
        y++;
        if (element.get_inquiries.length > 0) {
          if (section == 1) {
            this.tab_form_col_1.push(element);
            var array = this.set_inquery_data(element);
            this.inquiries_data_col1.push(array);
          } else {
            this.tab_form_col_2.push(element);
            var array = this.set_inquery_data(element);
            this.inquiries_data_col2.push(array);
          }
          this.$store.state.customer.print_selected.push(element.id);
          this.tabs.push({ id: element.id, name: element.name });
          if (y == length) {
            if (length != count) {
              length = length * 2;
              section = 1;
            }
          }
        }
      }
    },
    set_inquery_data(element) {
      var newarray = { arr: [] };
      for (let y = 0; y < element.get_inquiries.length; y++) {
        const elementy = element.get_inquiries[y];
        var is_file = 0,
          id = null,
          answer = null,
          file = null;
        if (elementy.inputs.type == "file") {
          is_file = 1;
        }
        if (elementy.register_form_answers != null) {
          id = elementy.register_form_answers.id;
          if (elementy.register_form_answers.is_file == 0) {
            answer = elementy.register_form_answers.answer;
          } else {
            file = elementy.register_form_answers.answer;
          }
        }
        newarray.arr.push({
          answer: answer,
          tab_id: elementy.pivot.tab_id,
          is_file: is_file,
          inquiry_id: elementy.pivot.inquiry_id,
          id: id,
          file: file,
        });
        // }
      }
      return newarray;
    },
    save_form(data, tab_index, type) {
      console.log(data);
      // this.loader_form = true;
      console.log(tab_index);
      let formData = new FormData();
      var inqueries = data[tab_index].arr;
      for (let i = 0; i < inqueries.length; i++) {
        const element = inqueries[i];
        formData.append(`id[${i}]`, element.id);
        formData.append(`answer[${i}]`, element.answer);
        formData.append(`tab_id[${i}]`, element.tab_id);
        formData.append(`is_file[${i}]`, element.is_file);
        formData.append(`inquiry_id[${i}]`, element.inquiry_id);
      }
      return CustomerService.add_form_register_inquery_from_admin(
        this.$route.params.id,
        formData
      ).then(
        (response) => {
          // this.loader_form = false;
          console.log(response);
          var new_answer = response.data.data.answer;
          for (let z = 0; z < inqueries.length; z++) {
            const element = inqueries[z];
            for (let m = 0; m < new_answer.length; m++) {
              const elementm = new_answer[m];
              if (
                elementm.tab_id == element.tab_id &&
                elementm.inquiry_id == element.inquiry_id
              ) {
                element.id = elementm.id;
                if (elementm.is_file == 1) {
                  element.answer = null;
                  element.file = elementm.answer;
                  if (type == 1) {
                    this.tab_form_col_1[tab_index].get_inquiries[
                      z
                    ].register_form_answers = elementm;
                  } else if (type == 2) {
                    this.tab_form_col_2[tab_index].get_inquiries[
                      z
                    ].register_form_answers = elementm;
                  }
                }
              }
            }
          }
          this.$store.dispatch("form/setNotify", {
            msg: response.data.message,
            type: "Success",
          });
        },
        (error) => {
          // this.loader_form = false;
          if (error.response.status != 401) {
            this.$store.dispatch("form/setNotify", {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            });
          }
        }
      );
    },
    print() {
      this.$store.commit("form/SET_DIALOG", true);
      this.$store.commit("form/SET_COLLECTION", "customer");
      this.modal_data.title = this.$i18n.t("select sections for print");
      this.$store.dispatch("form/setFormData", this.form_data_print);
      this.$store.dispatch("form/setData", this.form_style_print);
      this.$store.state.form.style_form[0].items = this.tabs;
      this.$store.commit("form/INFO_CALL", {
        name: "customer/setPrintSelected",
      });
    },
    show() {
      this.$store.commit("form/SET_DIALOG", true);
      this.$store.commit("form/SET_COLLECTION", "customer");
      this.modal_data.title = this.$i18n.t("select sections for print");
      this.$store.dispatch("form/setFormData", this.form_data_print);
      this.$store.dispatch("form/setData", this.form_style_print);
      this.$store.state.form.style_form[0].items = this.tabs;
      this.$store.commit("form/INFO_CALL", {
        name: "customer/showCertenSection",
      });
    },
    show_all() {
      this.$store.dispatch("customer/showAllSection", this.show_all_cards);
    },
    add_inquiry() {
      this.$store.commit("form/SET_DIALOG", true);
      this.$store.commit("form/SET_COLLECTION", "customer");
      this.modal_data.title = this.$i18n.t("inquiry.create new inquiry");
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.dispatch("form/setData", this.form_style);
      this.$store.state.form.style_form[0].items = this.inqueries;
      this.$store.commit("form/INFO_CALL", {
        name: "customer/saveInquery",
        id: this.$route.params.id,
      });
      // this.form_data.inquiry_id = "";
      // this.form_data.status = false;
      this.form_style[2].label_input = this.$i18n.t("Assign For Customer");
      this.form_style[0].visible = true;
      this.form_style[1].visible = true;
      this.form_style[3].visible = true;
      this.form_style[4].visible = true;
    },
    edit_inquery(item) {
      console.log(item);
      this.$store.commit("form/SET_DIALOG", true);
      this.$store.commit("form/SET_COLLECTION", "customer");
      this.modal_data.title =
        this.$i18n.t("inquiry.Edit Answer For Inquiry") +
        " " +
        item.inquery_lang;
      this.form_style[2].label_input = this.$i18n.t("Assign For Customer");
      this.form_style[1].type = item.type;
      this.form_style[0].disabled = true;
      this.form_style[0].visible = true;
      this.form_style[1].visible = true;
      this.form_style[3].visible = false;
      this.form_style[4].visible = true;

      this.$store.dispatch("form/setData", this.form_style);
      this.$store.state.form.style_form[0].items = this.inqueries;
      if (item.type != "file") {
        this.form_data.answer = item.answer;
      }
      if (item.global == 1) {
        this.form_data.status = true;
      } else {
        this.form_data.status = false;
      }
      if (item.admins == 1) {
        this.form_data.admins = true;
      } else {
        this.form_data.admins = false;
      }
      this.form_data.inquiry_id = item.inquery_id;
      this.form_data.type = item.type;
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.commit("form/INFO_CALL", {
        name: "customer/updateInquery",
        id: item.id,
      });
    },
    delete_inquery(item) {
      this.$store.commit("form/SET_DIALOG", true);
      this.$store.commit("form/SET_COLLECTION", "customer");
      this.modal_data.title =
        this.$i18n.t("inquiry.Delete Inquery") + " " + item.inquery_lang;
      this.form_style[2].label_input = this.$i18n.t(
        "inquiry.Delete from all applicant"
      );
      this.form_style[1].type = item.type;
      this.form_style[0].visible = false;
      this.form_style[1].visible = false;
      this.form_style[3].visible = false;
      this.form_style[4].visible = false;
      this.$store.dispatch("form/setData", this.form_style);
      this.$store.state.form.style_form[0].items = this.inqueries;
      if (item.type != "file") {
        this.form_data.answer = item.answer;
      }
      if (item.global == 1) {
        this.form_data.status = true;
      } else {
        this.form_data.status = false;
      }

      this.form_data.inquiry_id = item.inquery_id;
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.commit("form/INFO_CALL", {
        name: "customer/deleteInquery",
        id: item.id,
      });
    },
    delete_inquery_repeatable(item) {
      console.log(item);
      this.$store.commit("form/SET_DIALOG", true);
      this.$store.commit("form/SET_COLLECTION", "customer");
      this.modal_data.title =
        this.$i18n.t("inquiry.Delete Inquery") + " " + item.inquery_lang;
      this.form_style[2].label_input = this.$i18n.t(
        "inquiry.Delete from all applicant"
      );
      this.form_style[1].type = item.type;
      this.form_style[0].visible = false;
      this.form_style[1].visible = false;
      this.form_style[3].visible = false;
      this.form_style[4].visible = false;
      this.$store.dispatch("form/setData", this.form_style);
      // if (item.global == 1) {
      //   this.form_data.status = true;
      // } else {
      //   this.form_data.status = false;
      // }

      this.form_data.inquiry_id = item.inquiry_id;
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.commit("form/INFO_CALL", {
        name: "customer/deleteInqueryRepeatable",
        id: { id: item.id, customer_id: this.$route.params.id, item },
      });
    },
    save_inquery_repeatble(year, month) {
      var formData = new FormData();
      var inquiries = this.inquiries_data_monthly[year][month];
      console.log(inquiries);
      for (let i = 0; i < inquiries.length; i++) {
        const element = inquiries[i];
        formData.append(`answer[${i}]`, element.answer);
        formData.append(`id[${i}]`, element.id);
      }
      this.saveAnswerRepeatableInquiries(formData).then(
        (response) => {
          console.log(response);
          this.$store.dispatch("form/setNotify", {
            msg: response.data.message,
            type: "Success",
          });
        },
        (error) => {
          console.log(error);
          this.$store.dispatch("form/setNotify", {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          });
        }
      );
    },

    passwordsvalidate() {
      return this.$refs.resetPasswordForm.validate();
    },
    handleUpdate(valid) {
      this.loadingInfo = true;
      if (valid) {
        this.error_msg = "";
        const formData = new FormData();
        formData.append("admin", true);

        if (this.files != null) formData.append("avatar", this.files);
        this.form_info_basic.map(function (item) {
          if (item.value != null) {
            formData.append(item.value_text, item.value);
          }
        });

        axios({
          url: "users/update/" + this.$route.params.id,
          data: formData,
          method: "POST",
        })
          .then((response) => {
            this.loadingInfo = false;
            let user = response.data.user;
            this.form_info_basic.map(function (item) {
              item.value = user[item.value_text];
            });
            this.saved_avatar_src = response.data.user.avatar;
            this.$store.dispatch("form/setNotify", {
              msg: response.data.message,
              type: "Success",
            });
          })
          .catch((error) => {
            this.loadingInfo = false;
            if (error && error.response.status == 422) {
              let errors = Object.values(error.response.data.errors);
              this.error_msg = errors.toString();
            } else {
              this.error_msg =
                (error.response && error.response.data.message) ||
                error.message;
            }
            this.$store.dispatch("form/setNotify", {
              msg: this.error_msg,
              type: "Danger",
            });
          });
      } else {
        this.loadingInfo = false;
        this.$store.dispatch("form/setNotify", {
          msg: "Please Check errors and try again.",
          type: "Danger",
        });
        return;
      }
    },
    removeImage() {
      this.files = null;
      this.avatar_src = "";
    },

    DeleteImage() {
      this.error_msg = "";

      this.$swal({
        title: this.$i18n.t("Are you sure you want to delete profile image?"),
        text: this.$i18n.t("You won't be able to revert this!"),
        showCancelButton: true,
        confirmButtonText: this.$i18n.t("general.delete"),
        cancelButtonText: this.$i18n.t("Keep it"),
        customClass: {
          confirmButton: "btn bg-gradient-danger",
          cancelButton: "btn bg-gradient-success",
        },
        preConfirm: (remove) => {
          this.error_msg = "";
          axios({
            url: "users/delete/avatar/" + this.$route.params.id,
            method: "POST",
          })
            .then((response) => {
              this.avatar_src = "";
              this.saved_avatar_src = "";
              this.$store.dispatch("form/setNotify", {
                msg: response.data.message,
                type: "Success",
              });
            })
            .catch((error) => {
              if (error && error.response.status == 422) {
                let errors = Object.values(error.response.data.errors);
                this.error_msg = errors.toString();
              } else {
                this.error_msg =
                  (error.response && error.response.data.message) ||
                  error.message;
              }
              this.$store.dispatch("form/setNotify", {
                msg: this.error_msg,
                type: "Danger",
              });
            });
        },
      });
    },
    ResetPassword() {
      if (this.passwordsvalidate()) {
        this.$swal({
          title: this.$i18n.t(
            "Are you sure you want to reset this account password?"
          ),
          text: this.$i18n.t("You won't be able to revert this!"),
          showCancelButton: true,
          confirmButtonText: this.$i18n.t("Reset"),
          cancelButtonText: this.$i18n.t("Cancel"),
          customClass: {
            confirmButton: "btn bg-gradient-danger",
            cancelButton: "btn bg-gradient-success",
          },
          preConfirm: (remove) => {
            this.error_msg = "";
            this.loadingPassword = true;

            axios({
              url: "users/password/reset/" + this.$route.params.id,
              data: this.passwords,
              method: "POST",
            })
              .then((response) => {
                // this.passwords.password = "";
                // this.passwords.password_confirmation = "";
                this.$store.dispatch("form/setNotify", {
                  msg: response.data.message,
                  type: "Success",
                });
                this.loadingPassword = false;
              })
              .catch((error) => {
                console.log(error);
                this.loadingPassword = false;
                if (error && error.response.status == 422) {
                  let errors = Object.values(error.response.data.errors);
                  this.error_msg = errors.toString();
                } else {
                  this.error_msg =
                    (error.response && error.response.data.message) ||
                    error.message;
                }
                this.$store.dispatch("form/setNotify", {
                  msg: this.error_msg,
                  type: "Danger",
                });
              });
          },
        });
      } else {
        this.loadingPassword = false;
        this.error_msg = "Please Check errors and try again.";
        return;
      }
    },
    handleFilesUpload() {
      this.files = event.target.files[0];
      this.avatar_src = URL.createObjectURL(event.target.files[0]);
    },
  },
  created() {},
  mounted() {
    document.title = this.$i18n.t("general.profile");
    this.getDetails();
    this.get_chart_inquiries();

    let currentYear = new Date().getFullYear();
    let earliestYear = 1970;
    this.years.push(currentYear);
    this.selected_year = currentYear;
    while (currentYear >= earliestYear) {
      currentYear -= 1;
      this.years.push(currentYear);
    }
    // self.selected_inquiry = self.total_inquiry[0];
  },
};
</script>
<style scoped>
/* .chart_select {
  flex: 2;
}
.chart_head >>> .v-toolbar__content {
  justify-content: space-between;
}
.chart_head >>> .v-toolbar__title {
  flex: 1;
} */
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}

#chartBar {
  min-height: 342px;
}

.v-avatar {
  overflow: visible;
}
</style>