var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tabs.length > 0)?_c('v-col',_vm._l((_vm.tabs),function(item,i){return (
      (_vm.$store.state.auth.is_admin == 1 &&
        _vm.$store.state.customer.print_selected.includes(item.id)) ||
      _vm.$store.state.auth.is_admin == 0
    )?_c('v-card',{key:i,staticClass:"light mb-4",attrs:{"cols":"12"}},[_c('v-app-bar',{staticClass:"border-left-primary",attrs:{"color":"light"}},[_c('v-toolbar-title',[_vm._v(_vm._s(item.name))])],1),_c('v-card',{staticClass:"mx-auto border border-top-none px-5 py-7",attrs:{"title":""}},[_c('v-row',_vm._l((item.get_inquiries),function(inquery,index){return _c('v-col',{key:index,staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('div',{staticClass:"position-relative"},[_c('label',{staticClass:"text-xs text-typo font-weight-bolder ms-1"},[_vm._v(_vm._s(inquery.inquery_lang))])])]),(
                inquery.register_form_answers != null &&
                inquery.register_form_answers.is_file == 1 &&
                inquery.register_form_answers.answer != null
              )?_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('a',{staticClass:"text-decoration-none",attrs:{"target":"_blank","href":_vm.inquiries_data[i].arr[index].file}},[_c('v-btn',{staticClass:"text-dark font-weight-bolder py-6 px-2 shadow-0",attrs:{"color":"#fff","small":"","simple":""}},[_c('v-icon',{staticClass:"rotate-180",attrs:{"size":"18"}},[_vm._v("fas fa-file-pdf text-lg me-1")]),_vm._v(" "+_vm._s(_vm.$t("ticket.file"))+" ")],1)],1)]):_vm._e()],1),(inquery.inputs.type == 'file')?_c('div',{staticClass:"position-relative"},[_c('v-file-input',{attrs:{"show-size":"","counter":"","chips":""},model:{value:(_vm.inquiries_data[i].arr[index].answer),callback:function ($$v) {_vm.$set(_vm.inquiries_data[i].arr[index], "answer", $$v)},expression:"inquiries_data[i].arr[index].answer"}})],1):_c('div',{staticClass:"position-relative"},[_c('v-text-field',{staticClass:"font-size-input placeholder-lighter text-light-input",attrs:{"type":inquery.inputs.type,"outlined":"","color":"rgba(0,0,0,.6)"},model:{value:(_vm.inquiries_data[i].arr[index].answer),callback:function ($$v) {_vm.$set(_vm.inquiries_data[i].arr[index], "answer", $$v)},expression:"inquiries_data[i].arr[index].answer"}})],1)],1)}),1),_c('v-row',[_c('v-col',{class:_vm.classLang,attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"btn-primary mw-80",attrs:{"color":"white","loading":_vm.loader,"text":""},on:{"click":function($event){return _vm.save(i, _vm.type)}}},[_c('span',{attrs:{"slot":"loader"},slot:"loader"},[_c('v-progress-circular',{staticStyle:{"width":"20px","height":"20px"},attrs:{"indeterminate":"","color":"white"}})],1),_vm._v(" "+_vm._s(_vm.$t("form.save"))+" ")])],1)],1)],1)],1):_vm._e()}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }