<template>
  <v-col v-if="tabs.length > 0">
    <!--  -->
    <v-card
      cols="12"
      v-for="(item, i) in tabs"
      :key="i"
      v-if="
        ($store.state.auth.is_admin == 1 &&
          $store.state.customer.print_selected.includes(item.id)) ||
        $store.state.auth.is_admin == 0
      "
      class="light mb-4"
    >
      <v-app-bar color="light" class="border-left-primary">
        <v-toolbar-title>{{ item.name }}</v-toolbar-title>
      </v-app-bar>

      <v-card class="mx-auto border border-top-none px-5 py-7" title>
        <v-row>
          <v-col
            class="pt-0"
            v-for="(inquery, index) in item.get_inquiries"
            :key="index"
            cols="12"
          >
            <v-row>
              <v-col cols="12" sm="10">
                <div class="position-relative">
                  <label class="text-xs text-typo font-weight-bolder ms-1">{{
                    inquery.inquery_lang
                  }}</label>
                  <!-- <span
                          class="strick text-red mx-2"
                          v-if="inquery.register_form_inquiry.is_required == 1"
                          >*</span
                        > -->
                </div>
              </v-col>
              <v-col
                v-if="
                  inquery.register_form_answers != null &&
                  inquery.register_form_answers.is_file == 1 &&
                  inquery.register_form_answers.answer != null
                "
                cols="12"
                sm="2"
              >
                <a
                  target="_blank"
                  class="text-decoration-none"
                  :href="inquiries_data[i].arr[index].file"
                >
                  <v-btn
                    color="#fff"
                    class="text-dark font-weight-bolder py-6 px-2 shadow-0"
                    small
                    simple
                  >
                    <v-icon size="18" class="rotate-180"
                      >fas fa-file-pdf text-lg me-1</v-icon
                    >
                    {{ $t("ticket.file") }}
                  </v-btn>
                </a>
              </v-col>
            </v-row>
            <div class="position-relative" v-if="inquery.inputs.type == 'file'">
              <v-file-input
                v-model="inquiries_data[i].arr[index].answer"
                show-size
                counter
                chips
              ></v-file-input>
            </div>
            <div class="position-relative" v-else>
              <v-text-field
                :type="inquery.inputs.type"
                outlined
                color="rgba(0,0,0,.6)"
                class="font-size-input placeholder-lighter text-light-input"
                v-model="inquiries_data[i].arr[index].answer"
              >
              </v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <!-- {{ i }} -->
          <v-col :class="classLang" cols="12">
            <v-btn
              class="btn-primary mw-80"
              color="white"
              :loading="loader"
              text
              @click="save(i, type)"
            >
              <span slot="loader">
                <v-progress-circular
                  style="width: 20px; height: 20px"
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </span>
              {{ $t("form.save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </v-col>
</template>
<script>
export default {
  props: ["tabs", "inquiries_data", "loader", "type"],
  computed: {
    classLang() {
      return {
        "text-left": this.$vuetify.rtl,
        "text-right": !this.$vuetify.rtl,
      };
    },
  },
  methods: {
    save(i, type) {
      this.$emit("save", this.inquiries_data, i, type);
    },
  },
};
</script>